import { Controller } from 'stimulus';

const CLASSES = {
  'ACTIVE': 'cookie-consent--active'
}

export default class CookieConsentController extends Controller {
  cookieKey = '_pcrt_cookies_consented';

  connect() {
    this.init();
  }

  /**
   * Handle clicking cookie consent trigger
   */
  handleTriggerClick() {
    this.consent();
  }

  /**
   * Initialize consent data
   */
  init() {
    if (!this.hasCookie()) {
      this.show();
    }
  }

  /**
   * Set the consent cookie and hide the consent module
   */
  consent() {
    this.setCookie();
    this.hide();
  }

  /**
   * Show the cookie module
   */
  show() {
    this.element.classList.add(CLASSES.ACTIVE);
  }

  /**
   * Hide the cookie module
   */
  hide() {
    this.element.classList.remove(CLASSES.ACTIVE);
  }

  /**
   * Checks cookies for cookie-consent
   */
  hasCookie() {
    return document.cookie.split(';').filter((item) => item.includes(`${this.cookieKey}=`)).length > 0;
  }

  /**
   * Sets the cookie-consent cookie
   */
  setCookie() {
    let expire = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toGMTString();

    document.cookie = `${this.cookieKey}=true;expires=${expire};path=/`;
  }
}
