/**
 * Returns value of query parameter if it gets
 * @param {string} key Query parameter key
 */
export function getQueryParam(key) {
  const query = window.location.search.substring(1);
  const params = query.split('&');

  for (let i = 0; i < params.length; i++) {
    let pair = params[i].split('=');
    
    if (pair[0] === key) {
      return pair[1];
    }
  }
}
