import { Controller } from 'stimulus';

/**
 * A reference to the classes used in this controller
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'NO_SCROLL': 'no-scroll',
  'SHOW': 'modal--show',
};

/**
 * A reference to the selectors used in this controller
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'MODAL': '.modal',
  'FOCUSABLE': `
    a[href]:not([tabindex^="-"]),
    area[href]:not([tabindex^="-"]),
    input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"]),
    input[type="radio"]:not([disabled]):not([tabindex^="-"]):checked,
    select:not([disabled]):not([tabindex^="-"]),
    textarea:not([disabled]):not([tabindex^="-"]),
    button:not([disabled]):not([tabindex^="-"]),
    iframe:not([tabindex^="-"]),
    audio[controls]:not([tabindex^="-"]),
    video[controls]:not([tabindex^="-"]),
    [contenteditable]:not([tabindex^="-"]),
    [tabindex]:not([tabindex^="-"])
  `,
};

/**
 * A reference to the events used in this controller
 *
 * @property KEYS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'FOCUS': 'focus',
  'KEYDOWN': 'keydown',
};

/**
 * A reference to the keys used in this controller
 *
 * @property KEYS
 * @type {Object}
 * @private
 */
const KEYS = {
  'TAB': 'Tab',
  'ESCAPE': 'Escape',
};

export default class extends Controller {
  static targets = ['content']

  /**
   * Stimulus method called when controller
   * is connected to the DOM
   *
   * @method connect
   * @public
   */
  connect() {
    this.shown = false;
    this.previouslyFocused = null;
    this.onHandleKeyDown = e => this.handleKeyDown(e);
    this.onHandleFocus = e => this.handleFocus(e);
  }

  /**
   * Shows modal on page
   *
   * @method show
   * @public
   */
  show() {
    // If the dialog is already open, abort
    if (this.shown) {
      return;
    }

    document.documentElement.classList.add(CLASSES.NO_SCROLL);
    document.body.classList.add(CLASSES.NO_SCROLL);

    // Keep a reference to the currently focused element to be able to restore
    // it later
    this.previouslyFocused = document.activeElement;

    this.contentTarget.classList.add(CLASSES.SHOW);
    this.contentTarget.removeAttribute('aria-hidden');
    this.shown = true;

    this.connectKeyboardEvents();

    setTimeout(() => {
      this.updateFocusableElements();
      this.focusFirst();
    }, 1);
  }

  /**
   * Hides modal on page
   *
   * @method hide
   * @public
   */
  hide() {
    // If the dialog is already closed, abort
    if (!this.shown) {
      return;
    }

    document.documentElement.classList.remove(CLASSES.NO_SCROLL);
    document.body.classList.remove(CLASSES.NO_SCROLL);

    this.shown = false;
    this.contentTarget.classList.remove(CLASSES.SHOW);
    this.contentTarget.setAttribute('aria-hidden', true);

    this.disconnectKeyboardEvents();

    // If there was a focused element before the dialog was opened
    //  (and it has a `focus` method), restore the focus back to it
    if (this.previouslyFocused && this.previouslyFocused.focus) {
      this.previouslyFocused.focus();
    }
  }

  /**
   * Attaches event listeners for keys and focus
   *
   * @method connectKeyboardEvents
   * @public
   */
  connectKeyboardEvents() {
    document.addEventListener(EVENTS.KEYDOWN, this.onHandleKeyDown);
    document.body.addEventListener(EVENTS.FOCUS, this.onHandleFocus, true);
  }

  /**
   * Removes event listeners for keys and focus
   *
   * @method disconnectKeyboardEvents
   * @public
   */
  disconnectKeyboardEvents() {
    document.removeEventListener(EVENTS.KEYDOWN, this.onHandleKeyDown);
    document.body.removeEventListener(EVENTS.FOCUS, this.onHandleFocus, true);
  }

  /**
   * Updates the internal set of visible focusable elements within the modal
   *
   * @method updateFocusableElements
   * @public
   */
  updateFocusableElements() {
    const modal = this.element.querySelector(SELECTORS.MODAL);

    const focusables = Array.from(modal.querySelectorAll(SELECTORS.FOCUSABLE)).filter(element => {
      return !!(
        element.offsetWidth ||
        element.offsetHeight ||
        element.getClientRects().length
      );
    });

    this.firstFocusable = focusables[0];
    this.lastFocusable = focusables[focusables.length - 1];
  }

  /**
   * Handles focus events
   * Handles trapping for tabbing outside of modal context
   *
   * @method handleFocus
   * @param  {Event} e
   * @public
   */
  handleFocus(e) {
    const outsideModal = e.target.closest(SELECTORS.MODAL) === null;

    if (outsideModal) {
      this.focusFirst();
    }
  }

  /**
   * Sets focus to first focusable item in modal
   *
   * @method focusFirst
   * @public
   */
  focusFirst() {
    this.firstFocusable.focus();
  }

  /**
   * Sets focus to last focusable item in modal
   *
   * @method focusLast
   * @public
   */
  focusLast() {
    this.lastFocusable.focus();
  }

  /**
   * Handle keydown events
   *
   * @method handleKeyDown
   * @param  {Event} e
   * @public
   */
  handleKeyDown(e) {
    // If the dialog is shown and the ESCAPE key is being pressed, prevent any
    // further effects from the ESCAPE key and hide the dialog, unless its role
    // is 'alertdialog', which should be modal
    if (
      this.shown &&
      e.key === KEYS.ESCAPE &&
      !this.isAlertDialog()
    ) {
      e.preventDefault();
      this.hide();
    }

    // If the dialog is shown and the TAB key is being pressed, make sure the
    // focus stays trapped within the dialog element
    if (this.shown && e.key === KEYS.TAB) {
      this.trapTabKey(e);
    }
  }

  /**
   * Handles trapping for tab events
   *
   * @method trapTabKey
   * @param  {Event} e
   * @public
   */
  trapTabKey(e) {
    this.updateFocusableElements();

    if (!e.shiftKey && e.target === this.lastFocusable) {
      e.preventDefault();
      this.focusFirst();
    } else if (e.shiftKey && e.target === this.firstFocusable) {
      e.preventDefault();
      this.focusLast();
    }
  }

  /**
   * Calls hide on background click
   * if role is not alertdialog
   *
   * @method backgroundClick
   * @public
   */
  backgroundClick() {
    if (!this.isAlertDialog) {
      this.hide();
    }
  }

  /**
   * Returns true if role is alertdialog
   * @return {Boolean} alertdialog
   */
  get isAlertDialog() {
    return this.contentTarget.getAttribute('role') === 'alertdialog'
  }
}
