import { Controller } from 'stimulus';

/**
 * A reference to the selectors used in this controller
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'INPUT_WRAPPER': '.field__option',
};

/**
 * A reference to the classes used in this controller
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'ACTIVE': 'field__option--selected',
};

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.toggleInputs();
  }

  toggleInputs() {
    this.inputTargets.forEach(t => {
      t.closest(SELECTORS.INPUT_WRAPPER).classList.toggle(CLASSES.ACTIVE, t.checked);
    });
  }
}
