import { Controller } from 'stimulus';
import { getQueryParam } from '~/utilities';

/**
 * Wistia Events
 */
const EVENTS = {
  'CLICK': 'click',
  'KEYDOWN': 'keydown',
  'POPOVER_SHOW': 'popovershow',
  'POPOVER_HIDE': 'popoverhide'
};

/**
 * Keys used in this controller
 */
const KEYS = {
  'ENTER': 13
};

export default class extends Controller {
  static targets = ['thumbnail', 'link'];

  video = {};
  videoVisible = false;
  videoId = '';

  connect() {
    this.videoId = this.data.get('videoId');
    this.loadVideo(this.videoId, this.videoReadyFn);
  }

  /**
   * callback function for wistia player
   * @param {object} Wistia.video video
   */
  videoReadyFn(video) {
    this.video = video;

    const closeButton = this.video.popover._closeButton;

    if (closeButton) {
      closeButton.addEventListener(EVENTS.KEYDOWN, this.handleCloseButtonKeydown.bind(this));
    }

    if (this.hasThumbnailTarget) {
      this.thumbnailTarget.addEventListener(EVENTS.KEYDOWN, this.handleThumbnailKeydown.bind(this));
    }

    this.video.bind(EVENTS.POPOVER_SHOW, this.handlePopoverShow.bind(this));
    this.video.bind(EVENTS.POPOVER_HIDE, this.handlePopoverHide.bind(this));


    if (getQueryParam('video') === this.videoId) {
      this.showAndPlayIfHidden();
    }
  }

  /**
   * Loads a wistia video asynchronously and fires callback when ready.
   *
   * @method loadVideo
   * @public
   */
  loadVideo(videoId, readyFn) {
    window._wq = window._wq || [];
    window._wq.push({
      'id': videoId,
      'onReady': readyFn.bind(this)
    });
  }

  /**
   * Shows and plays the video if hidden otherwise does nothing
   */
  showAndPlayIfHidden() {
    if (this.videoVisible) {
      return;
    }

    this.video.popover.show();
    this.video.play();
  }

  /**
   * Handle Thumbnail keydown event
   * @param {KeyboardEvent} e
   */
  handleThumbnailKeydown(e) {
    if (e.keyCode === KEYS.ENTER) {
      this.showAndPlayIfHidden();
    }
  }

  /**
   * Handle Clicking video link
   * @param {MouseEvent} e
   */
  handleVideoLinkClick(e) {
    e.preventDefault();

    this.showAndPlayIfHidden();
  }

  /**
   * Handle showing wistia popover
   */
  handlePopoverShow() {
    this.videoVisible = true;
  }

  /**
   * Handle hiding wistia popover
   */
  handlePopoverHide() {
    this.videoVisible = false;
  }

  /**
   *
   * @param {*} e
   */
  handleCloseButtonKeydown(e) {
    if (e.keyCode === KEYS.ENTER) {
      this.video.popover.hide();
    }
  }
}
