import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['circle'];

  radius = 0;
  circumference = 0;

  connect() {
    this.initProgress();
    this.updateProgress();
  }

  /**
   * Update progress bar from data attribute
   */
  updateProgress() {
    const value = this.data.get('value');

    this.setProgress(value);
  }

  /**
   * Initialize the progress circle values
   */
  initProgress() {
    const circle = this.circleTarget;

    this.radius = circle.r.baseVal.value;
    this.circumference = this.radius * 2 * Math.PI;
    circle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
    circle.style.strokeDashoffset = this.circumference;
  }

  /**
   * Set progress circle to a percentage
   * @param {Number} percent 
   */
  setProgress(percent = 0) {
    const offset = this.circumference - percent / 100 * this.circumference;
    
    this.circleTarget.style.strokeDashoffset = offset;
  }
}
