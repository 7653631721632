import { Controller } from 'stimulus';

/**
 * A reference to the classes used in this controller
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'FADED': 'flash--faded',
};

export default class extends Controller {
  /**
   * Stimulus method called when controller
   * is connected to the DOM
   *
   * @method connect
   * @public
   */
  connect() {
    const showLength = 5000;

    setTimeout(() => {
      this.hide();
    }, showLength);
  }

  /**
   * Hides modal on page
   *
   * @method hide
   * @public
   */
  hide() {
    requestAnimationFrame(() => {
      this.element.classList.add(CLASSES.FADED);
    });
  }
}
