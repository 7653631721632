import { Controller } from 'stimulus';

/**
 * A reference to the classes used in this controller
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'EXPANDED': 'sidebar--expanded',
  'NO_SCROLL': 'no-scroll',
};

export default class extends Controller {
  static targets = ['drawer', 'trigger', 'icon', 'menu'];

  /**
   * Handle toggle Event
   *
   * @method onToggle
   * @public
   */
  onToggle() {
    this.drawerTarget.classList.toggle(CLASSES.EXPANDED);
    this.iconTarget.classList.toggle(this.data.get('triggerClass'));

    document.documentElement.classList.toggle(CLASSES.NO_SCROLL);
    document.body.classList.toggle(CLASSES.NO_SCROLL);

    const isClosing = !this.drawerTarget.classList.contains(CLASSES.EXPANDED);

    if (isClosing) {
      this.submenuControllers.forEach(controller => controller.hide());
    }
  }

  /**
   * Returns array of references to submenu controllers
   *
   * @property
   * @public
   */
  get submenuControllers() {
    return this.menuTargets.map(el => this.application.getControllerForElementAndIdentifier(el, 'sidebar-menu'));
  }
}
