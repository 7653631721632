import { Controller } from 'stimulus';

const MIN_LENGTH = 8;

const validator = {
  /**
   * Check if string input has numbers
   * @param {string} value 
   */
  hasNumber(value) {
    return /\d/.test(value);
  },

  /**
   * Check if string has uppercase
   * @param {string} value 
   */
  hasUppercase(value) {
    return /[A-Z]/.test(value);
  },

  /**
   * Check if string has lowercase values
   * @param {string} value 
   */
  hasLowercase(value) {
    return /[a-z]/.test(value);
  },

  /**
   * Check if string meets min length
   * @param {string} value 
   */
  hasLength(value) {
    return value.length >= MIN_LENGTH;
  }
};

export default class extends Controller {
  static targets = ['input', 'length', 'number', 'upper', 'lower'];

  validClass = 'password-check__field--valid';

  value = '';

  /**
   * Handle input event
   * @param {Event} e 
   */
  handleChange(e) {
    this.updateValue(e.target.value);

    const fields = [
      { target: this.numberTarget, check: validator.hasNumber(this.value) },
      { target: this.upperTarget, check: validator.hasUppercase(this.value) },
      { target: this.lowerTarget, check: validator.hasLowercase(this.value) },
      { target: this.lengthTarget, check: validator.hasLength(this.value) }
    ];

    fields.forEach(field => this.updateField(field.target, field.check));
  }

  /**
   * Update stored value
   * @param {string} value 
   */
  updateValue(value) {
    this.value = value;
  }

  /**
   * Update a target field class
   * @param {Target} target 
   * @param {boolean} check 
   */
  updateField(target, check) {
    target.classList.toggle(this.validClass, check);
  }
}
