import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item', 'trigger']

  /**
   * toggles the open/closed state of the accordion items
   *
   */
  toggleState() {
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.toggleClass);
    });
  }

  /**
   * Retrieves the class to toggle
   * @return {String} The class to toggle on the accordion items
   */
  get toggleClass() {
    return this.data.get('class');
  }
}
