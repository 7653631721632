import { Controller } from 'stimulus';

export default class extends Controller {
  /**
   * Stimulus method called when controller
   * is connected to the DOM
   *
   * @method connect
   * @public
   */
  connect() {
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    document.cookie = `_pcrt_browser_timezone=${time_zone};path=/`;
  }
}
