import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link']

  connect() {
    this.lastScrollY = window.pageYOffset;
    this.offset = this.element.offsetTop;
    this.scrolling = false;

    this.toggleSticky();
  }

  handleScrollEvent() {
    this.lastScrollY = window.pageYOffset;

    if (!this.scrolling) {
      window.requestAnimationFrame(() => {
        this.toggleSticky();
        this.scrolling = false;
      });
      
      this.scrolling = true;
    }
  }

  /**
   * Fires when clicking links in page navigation
   * @param {MouseEvent} e 
   */
  handleAnchorClick(e) {
    const anchor = e.currentTarget;
    const sectionId = anchor.hash;

    if (!sectionId) {
      return;
    }

    e.preventDefault();

    this.scrollToSection(sectionId); 
    this.activateAnchor(anchor);
  }

  get isSticky() {
    return this.lastScrollY >= this.offset;
  }

  /**
   * Activate a clicked anchor and deactivate all others
   * @param {HTMLElement} anchor 
   */
  activateAnchor(anchor) {
    this.linkTargets.forEach(link => link.classList.toggle('active', link === anchor));
  }

  /**
   * Scrolls viewport to id'ed section
   * @param {string} sectionId Id of section to scroll into
   */
  scrollToSection(sectionId) {
    const el = document.querySelector(sectionId);
    let top = el.getBoundingClientRect().top + this.lastScrollY;
    const willStick = top >= this.offset;
    const offset = willStick ? this.element.clientHeight : 0;

    top -= offset;
    
    window.scrollTo({ top: top, behavior: 'smooth' });
  }

  toggleSticky() {
    const marginTop = this.isSticky ? this.element.clientHeight : 0;

    this.element.classList.toggle('page-navigation--sticky', this.isSticky);
    document.body.style.marginTop = `${marginTop}px`;
  }
}
