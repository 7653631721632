import { Controller } from 'stimulus';

/**
 * Constant referencing menu classes
 */
const CLASSES = {
  active: 'menu--active',
}

/**
 * Menu
 * Controller for basic toggling of a menu component
 */
export default class extends Controller {
  static targets = ['activator'];
  /**
   * active state of the menu
   */
  active = false;

  connect() {
    window.addEventListener('click', this.handleWindowClick.bind(this));

    this.initActivator();
  }

  disconnect() {
    window.removeEventListener('click', this.handleWindowClick.bind(this));
  }

  /**
   * Handle clicking window when menu is opened
   * @param {Event} e 
   */
  handleWindowClick(e) {
    if (this.active) {
      this.toggleActive(e);
    }
  }

  /**
   * Initialize activator events
   */
  initActivator() {
    this.activatorTarget.addEventListener('click', e => e.stopPropagation());
  }

  /**
   * Toggle the active state of the menu
   */
  toggleActive() {
    this.active = !this.active;

    this.element.classList.toggle(CLASSES.active);

    this.toggleActivator();
  }

  /**
   * toggle activator-target-class on the activator target
   */
  toggleActivator() {
    if (this.data.has('activatorClass')) {
      this.activatorTarget.classList.toggle(this.data.get('activatorClass'));
    }
  }
}
