import { Controller } from 'stimulus';

/**
 * A reference to the events used in this view
 */
const EVENTS = {
  'CHANGE': 'change',
};

export default class extends Controller {
  static targets = ['trigger', 'pnta']

  /**
   * Stimulus method called when controller
   * is initialized
   *
   * @method initialize
   * @public
   */
  initialize() {
    this.onHandleChange = e => this.handleChange(e);
  }

  /**
   * Stimulus method called when controller
   * is connected to the DOM
   *
   * @method connect
   * @public
   */
  connect() {
    this.element.addEventListener(EVENTS.CHANGE, this.onHandleChange);
  }

  /**
   * Stimulus method called when controller
   * is disconnected to the DOM
   *
   * @method disconnect
   * @public
   */
  disconnect() {
    this.element.removeEventListener(EVENTS.CHANGE, this.onHandleChange);
  }

  /**
   * Calls methods on change event
   *
   * @method handleChange
   * @param {Event} e
   * @public
   */
  handleChange(e) {
    this.handleTrigger(e);
    this.handlePnta(e);
  }

  /**
   * Calls reset with appropriate elements
   *
   * @method handleTrigger
   * @param {Event} e
   * @public
   */
  handleTrigger(e) {
    if (this.hasTriggerTarget && this.triggerTargets.includes(e.target)) {
      this.reset(this.resettableElements.filter(el => el !== e.target));
    }
  }

  /**
   * Calls reset with appropriate elements
   *
   * @method handlePnta
   * @param {Event} e
   * @public
   */
  handlePnta(e) {
    if (this.hasPntaTarget) {
      if (this.pntaTarget === e.target) {
        this.reset(this.resettableElements.filter(el => el !== e.target));
      } else {
        this.reset(this.pntaTargets);
      }
    }
  }

  /**
   * Loops through resetable inputs and dispatches events
   *
   * @method reset
   * @param {Array} Elements
   * @public
   */
  reset(elements) {
    elements.forEach(el => {
      if (el.tagName.toLowerCase() === 'input'){
        this[el.type](el);
      } else {
        this[el.tagName.toLowerCase()](el);
      }

      el.dispatchEvent(new Event(EVENTS.CHANGE));
    });
  }

  /**
   * Reset checkbox input
   *
   * @method checkbox
   * @param {Object} Input
   * @public
   */
  checkbox(input) {
    if (input.checked === true) {
      input.checked = false;
    }
  }

  /**
   * Reset hidden input
   *
   * @method hidden
   * @param {Object} Input
   * @public
   */
  hidden(input) {
    input.value = '';
  }

  /**
   * Reset radio input
   *
   * @method radio
   * @param {Object} Input
   * @public
   */
  radio(input) {
    input.checked = false;
  }

  /**
   * Reset select input
   *
   * @method select
   * @param {Object} Input
   * @public
   */
  select(input) {
    input.selectedIndex = 0;
  }

  /**
   * Reset text input
   *
   * @method text
   * @param {Object} Input
   * @public
   */
  text(input) {
    input.value = '';
  }

  /**
   * Reset textarea input
   *
   * @method textarea
   * @param {Object} Input
   * @public
   */
  textarea(input) {
    input.value = '';
  }

  /**
   * Retrieves array of resettable elements
   * @return {Array} Elements
   */
  get resettableElements() {
    const selector = 'input:not([type="submit"]):not([type="hidden"]), select, textarea';

    return Array.from(this.element.querySelectorAll(selector));
  }
}
