import { Controller } from 'stimulus';

/**
 * A reference to the classes used in this controller
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'HIDDEN': 'hidden',
};

export default class extends Controller {
  static targets = ['expiringSoon', 'expired', 'expirationTimer']

  /**
   * Stimulus method called when controller
   * is connected to the DOM
   *
   * @method connect
   * @public
   */
  connect() {
    if (this.timeoutIsActive) {
      this.startTimers();
    }
  }

  /**
   * Stimulus method called when controller
   * is disconnected to the DOM
   *
   * @method disconnect
   * @public
   */
  disconnect() {
    this.stopTimers();
  }

  /**
   * Creates the timers for showing the
   * expiring soon and expired content
   *
   * @method startTimers
   * @public
   */
  startTimers() {
    this.expirationTimerValue = this.timeoutIn - this.alertIn;

    this.expiringSoonTimeout = setTimeout(() => {
      this.showExpiringSoon();
    }, this.alertIn * 1000);

    this.expiredTimeout = setTimeout(() => {
      this.showExpired();
    }, this.timeoutIn * 1000);
  }

  /**
   * Clears the timers and interval
   *
   * @method stopTimers
   * @public
   */
  stopTimers() {
    clearTimeout(this.expiringSoonTimeout);
    clearTimeout(this.expiredTimeout);
    clearInterval(this.expirationTimerInterval);
  }

  /**
   * Shows expiring soon modal content, hides expired content
   *
   * @method showExpiringSoon
   * @public
   */
  showExpiringSoon() {
    this.expiringSoonTarget.classList.remove(CLASSES.HIDDEN);
    this.expiredTarget.classList.add(CLASSES.HIDDEN);

    this.expirationTimerInterval = setInterval(() => {
      this.updateExpirationTimer();
    }, 1000);

    this.modalController.show();
  }

  /**
   * Shows expired content, hides expiring soon content
   *
   * @method showExpired
   * @public
   */
  showExpired() {
    clearInterval(this.expirationTimerInterval);

    this.expiredTarget.classList.remove(CLASSES.HIDDEN);
    this.expiringSoonTarget.classList.add(CLASSES.HIDDEN);

    this.modalController.show();
  }

  /**
   * Sets the expiration timer element text
   *
   * @method updateExpirationTimer
   * @public
   */
  updateExpirationTimer() {
    this.expirationTimerValue -= 1;

    if (this.expirationTimerValue < 0) {
      this.expirationTimerValue = 0;
    }

    this.expirationTimerTarget.textContent = this.formattedTime(this.expirationTimerValue);
  }

  /**
   * Handles click events on session renewal link
   *
   * @method handleRenewSessionClick
   * @param  {Event} event
   * @public
   */
  renew(event) {
    event.preventDefault();

    fetch(event.currentTarget.href).then(() => this.resetExpiration());

    this.modalController.hide();
  }

  /**
   * Resets expiration timers/intervals and timer display value
   *
   * @method resetExpiration
   * @public
   */
  resetExpiration() {
    this.stopTimers();
    this.startTimers();
  }

  /**
   * Formats a seconds value to HH:MM:SS where HH is optional
   *
   * @method formattedTime
   * @param  {Integer} timeInSeconds
   * @return {String} formattedTime in HH:MM:SS
   * @public
   */
  formattedTime(timeInSeconds) {
    const secondsInHour = 3600;

    const hours = Math.floor(timeInSeconds / secondsInHour) % 24;
    const minutes = Math.floor(timeInSeconds / 60) % 60;
    const seconds = timeInSeconds % 60;

    const formattedTime = [hours, minutes, seconds].map(
      v => v < 10 ? '0' + v : v
    ).filter(
      (v, i) => v !== '00' || i > 0
    ).join(':');

    return formattedTime;
  }

  /**
   * Retrieves string to determine minutes before timeout
   * @return {String} timeout-in
   */
  get timeoutIn() {
    return this.data.get('timeout-in');
  }

  /**
   * Retrieves string to determine minutes
   * before timeout alert is shown
   * @return {String} alert-in
   */
  get alertIn() {
    return this.data.get('alert-in');
  }

  /**
   * Retrieves string to determine if the timeout modal should be active
   * - converts string value to boolean
   * @return {Boolean} timeout-is-active
   */
  get timeoutIsActive() {
    const value = this.data.get('timeoutIsActive');

    return JSON.parse(value);
  }

  /**
   * Retrieves instance of modal controller
   * @return {Controller} modalController
   */
  get modalController() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'modal');
  }
}
