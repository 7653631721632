import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggle', 'trigger']

  onToggle() {
    if (this.triggerTarget.querySelector(':checked') === null) return;

    const type = this.triggerTarget.querySelector(':checked').dataset.triggerTitle;

    this.toggleTargets.forEach(target => {
      target.classList.toggle('hidden', target.dataset.type !== type);
    });
  }
}
