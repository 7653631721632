import { Controller } from 'stimulus';

/**
 * A reference to the classes used in this controller
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'EXPANDED': 'sidebar__submenu--expanded',
};

export default class extends Controller {
  static targets = ['menu'];

  show() {
    this.menuTarget.classList.add(CLASSES.EXPANDED);
  }

  hide() {
    this.menuTarget.classList.remove(CLASSES.EXPANDED);
  }
}
