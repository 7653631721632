import { Controller } from 'stimulus';

const SELECTORS = {
  FORM_OPTION: '.field__option',
  ACTION_BAR: '.action-bar'
}

const CLASSES = {
  FIELD_OPTION_FOCUSED: 'field__option--focused'
};

const EVENTS = {
  'FOCUS': 'focus',
  'FOCUSIN': 'focusin',
  'BLUR': 'blur'
};

/**
 * Controller to handle various form states
 */
export default class extends Controller {
  connect() {
    this.initHandlers();
    this.setOffset();
  }

  disconnect() {
    this.destroyHandlers();
  }

  /**
   * Options for scrollintoview
   * @type {ScrollIntoViewOptions}
   */
  scrollOptions = { block: 'nearest' }

  /**
   * Returns a list of form inputs from the current form instance
   */
  get formOptions() {
    return this.element.querySelectorAll(SELECTORS.FORM_OPTION);
  }

  get actionBar() {
    return this.element.querySelector(SELECTORS.ACTION_BAR);
  }

  /**
   * Attach listeners to field option inputs
   */
  initHandlers() {
    this.formOptions.forEach(option => {
      const input = this.getFormOptionInput(option);

      if (input) {
        input.addEventListener(EVENTS.FOCUS, () => this.handleOptionFocus(option));
        input.addEventListener(EVENTS.BLUR, () => this.handleOptionBlur(option));
      }
    });

    this.element.addEventListener(EVENTS.FOCUSIN, e => this.handleFormFocus(e));
  }

  setOffset() {
    let offset = 0;

    if (this.actionBar) {
      offset = this.actionBar.clientHeight;
    }

    this.offset = offset;
  }

  /**
   * Remove listeners from field option inputs
   */
  destroyHandlers() {
    this.formOptions.forEach(option => {
      const input = this.getFormOptionInput(option);

      if (input) {
        input.removeEventListener(EVENTS.FOCUS, this.handleOptionFocus(option));
        input.removeEventListener(EVENTS.BLUR, this.handleOptionBlur(option));
      }
    });
  }

  /**
   * Parse form option and retrieve affected input element
   * @param {HTMLElement} option field option of type checkbox or radio
   * @returns {HTMLInputElement} input element
   */
  getFormOptionInput(option) {
    return option.querySelector('input[type="checkbox"], input[type="radio"]');
  }
  /**
   * Returns true if element is offscreen
   * @param {HTMLElement} el 
   */
  isElementOffscreen(el) {
    const rect = el.getBoundingClientRect();

    const offscreenTop = rect.top < window.pageYOffset;
    const offscreenBottom = rect.bottom > (window.innerHeight - this.offset);
   
    return offscreenTop || offscreenBottom;
  }

  handleFormFocus(e) {
    const el = e.target;
    const isOffscreen = this.isElementOffscreen(el);

    if (isOffscreen) {
      el.scrollIntoView(this.scrollOptions);
    }
  }

  handleOptionFocus(option) {
    if (!option) return;

    option.classList.add(CLASSES.FIELD_OPTION_FOCUSED);
  }

  handleOptionBlur(option) {
    if (!option) return;

    option.classList.remove(CLASSES.FIELD_OPTION_FOCUSED);
  }
}
