import { Controller } from 'stimulus';

export default class extends Controller {
  /**
   * Stimulus method called when controller
   * is connected to the DOM
   *
   * @method connect
   * @public
   */
  connect() {
    const step = parseInt(this.data.get('step'), 10);
    const totalSteps = parseInt(this.data.get('totalSteps'), 10);

    this.calculateProgress(step, totalSteps);
  }

  /**
   * Calculate percent complete based on current
   * step and total steps
   *
   * @method calculateProgress
   * @param {int} step
   * @param {int} totalSteps
   * @public
   */
  calculateProgress(step, totalSteps) {
    const percentComplete = (step / totalSteps) * 100;

    this.setFormProgress(percentComplete);
  }

  /**
   * Set correct width of progress bar based on
   * percent complete
   *
   * @method setFormProgress
   * @param {int} percentComplete
   * @public
   */
  setFormProgress(percentComplete) {
    this.element.style.width = `${percentComplete}%`;
  }
}
