import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'toggle'];

  inputType = {
    input: 'text',
    password: 'password'
  };

  showPassword = false;

  toggleText = {
    show: 'Show',
    hide: 'Hide'
  }

  _toggle() {
    this.showPassword = !this.showPassword;

    let type = this.inputType.password;
    let toggleText = this.toggleText.show;

    if (this.showPassword) {
      type = this.inputType.input;
      toggleText = this.toggleText.hide;
    }

    this.inputTarget.setAttribute('type', type);
    this.toggleTarget.innerHTML = toggleText;
  }

  handleToggleShowPassword(e) {
    e.preventDefault();
    e.stopPropagation();
    this._toggle();
  }
}
