import { Controller } from 'stimulus';

const KEYS = {
  'ARROW_UP': 'ArrowUp',
  'ARROW_DOWN': 'ArrowDown',
  'TAB': 'Tab'
};

const EVENTS = {
  'KEYUP': 'keyup',
  'KEYDOWN': 'keydown',
  'CHANGE': 'change',
  'DESELECT': 'deselect',
  'MOUSEUP': 'mouseup'
};

export default class extends Controller {
  connect() {
    this.attachInputEvents();
    this.attachOptionEvents();
  }

  disconnect() {
    this.destroyInputEvents();
    this.destroyOptionEvents();
  }

  /**
   * A reference to the primary other text input
   * @type {HTMLInputElement} Text input
   */
  get input() {
    return this.element;
  }

  /**
   * A reference to the outer Label element
   * @type {HTMLLabelElement} Input Label
   */
  get label() {
    return this.input.closest('.field__option label');
  }

  /**
   * Returns first input within the label
   * @type {HTMLInputElement}
   */
  get optionInput() {
    return this.label.querySelector('input[type="checkbox"], input[type="radio"]');
  }

  /**
   * Returns option list inputs in an array
   * @type {Array<HTMLInputElement>}
   */
  get allOptionInputs() {
    return Array.from(
      this.label.closest('.field__options')
        .querySelectorAll('.field__option input[type="checkbox"], .field__option input[type="radio"]')
    );
  }

  /**
   * Returns option inputs that arent part of the `other` input
   * @type {Array<HTMLInputElement>}
   */
  get otherOptionInputs() {
    return this.allOptionInputs.filter(input => input !== this.optionInput);
  }

  /**
   * Returns true if the Custom Input is a radio
   * @type {boolean}
   */
  get isRadio() {
    return this.optionInput.type === 'radio';
  }

  /**
   * Returns true if the Custom Input is a checkbox
   * @type {boolean}
   */
  get isCheckbox() {
    return this.optionInput.type === 'checkbox';
  }

  /**
   * Returns true if the option input is checked
   * @type {boolean}
   */
  get isChecked() {
    return this.optionInput.checked;
  }

  /**
   * Returns true if the text input has value
   * @type {boolean}
   */
  get hasValue() {
    return this.input.value.length > 0;
  }

  /**
   * Check e.key against passed in key
   * @param {*} e 
   * @param {Keys} key 
   */
  isKey(e, key) {
    return e.key === key;
  }

  /**
   * Checks if key is an arrow key
   * @param {KeyboardEvent} e 
   */
  isArrowKey(e) {
    return this.isKey(e, KEYS['ARROW_DOWN'])
        || this.isKey(e, KEYS['ARROW_UP']);
  }

  /**
   * Checks if key is tab key
   * @param {KeyboardEvent} e 
   */
  isTabKey(e) {
    return this.isKey(e, KEYS['TAB']);
  }

  initInputHandlers() {
    this.onHandleInputKeys = e => this.handleInputKeys(e);
  }

  initOptionHandlers() {
    this.onHandleOptionKeys = e => this.handleOptionKeys(e);
    this.onHandleOptionDeselect = () => this.handleOptionDeselect();
    this.onHandleOptionState = () => this.handleOptionState();
  }

  initOtherOptionHandlers() {
    this.onHandleOtherOptionChange = () => this.handleOtherOptionChange();
  }

  /**
   * Attach events to text input
   */
  attachInputEvents() {
    this.initInputHandlers();

    this.input.addEventListener(EVENTS.KEYUP, this.onHandleInputKeys);
  }

  /**
   * Attach events to option input
   */
  attachOptionEvents() {
    this.initOptionHandlers();

    this.optionInput.addEventListener(EVENTS.KEYDOWN, this.onHandleOptionKeys);
    this.optionInput.addEventListener(EVENTS.DESELECT, this.onHandleOptionDeselect);
    this.optionInput.addEventListener(EVENTS.CHANGE, this.onHandleOptionState);

    this.attachOtherOptionEvents();
  }

  /**
   * Attach events to a list of other option inputs
   */
  attachOtherOptionEvents() {
    this.initOtherOptionHandlers();

    this.otherOptionInputs.forEach(input => {
      input.addEventListener(EVENTS.CHANGE, this.onHandleOtherOptionChange);
    });
  }
  /**
   * Destroy events on text input
   */
  destroyInputEvents() {
    this.input.removeEventListener(EVENTS.KEYUP, this.onHandleInputKeys);
  }

  /**
   * Destroy events on option input
   */
  destroyOptionEvents() {
    this.optionInput.removeEventListener(EVENTS.KEYDOWN, this.onHandleOptionKeys);
    this.optionInput.removeEventListener(EVENTS.DESELECT, this.onHandleOptionDeselect);
    this.optionInput.removeEventListener(EVENTS.CHANGE, this.onHandleOptionState);

    this.destroyOtherOptionEvents();
  }

  /**
   * Destroy events on other options
   */
  destroyOtherOptionEvents() {
    this.otherOptionInputs.forEach(input => {
      input.removeEventListener(EVENTS.CHANGE, this.onHandleOtherOptionChange);
    });
  }

  /**
   * Handle user clicking on input label wrapper
   */
  handleOptionState() {
    if (this.hasValue && !this.isChecked) {
      this.clearInput();
    }

    if (this.isChecked) {
      this.focusInput();
    }
  }

  /**
   * Handle User keystrokes inside text input
   * @param {KeyboardEvent} e 
   */
  handleInputKeys(e) {
    if (this.hasValue
        && !this.isChecked
        && !this.isTabKey(e)
        && !this.isArrowKey(e))
    {
      this.setOptionChecked(true);
    }
  }

  /**
   * Handle custom deselect event, fired on keystrokes usually
   */
  handleOptionDeselect() {
    this.clearInput();
  }

  /**
   * Handle change events for other options in the list (not the current option input)
   */
  handleOtherOptionChange() {
    if (this.hasValue && this.isRadio) {
      this.clearInput();
    }
  }

  /**
   * Handle user keystrokes when focused on optionInput
   * @param {KeyboardEvent} e 
   */
  handleOptionKeys(e) {
    // if user hits arrow keys on radio, fire deselect on this option, clear input
    if (this.isRadio && this.isArrowKey(e)) {
      this.fireDeselectEvent();
    }

    // if user hits tab on checkbox, fire deselect on this option
    if (this.isCheckbox && this.isTabKey(e) && !this.isChecked) {
      this.fireDeselectEvent();
    }
  }

  /**
   * Sets the value of the option input
   * @param {boolean} val 
   */
  setOptionChecked(val) {
    this.optionInput.checked = val;
    this.updateOption();
  }

  focusInput() {
    setTimeout(() => {
      this.input.focus();
    }, 10);
  }

  /**
   * Fires a change event on the option to trigger class update
   */
  updateOption() {
    this.optionInput.dispatchEvent(new Event(EVENTS.CHANGE));
  }

  /**
   * Fires a `deselect` event on the option
   */
  fireDeselectEvent() {
    this.optionInput.dispatchEvent(new Event(EVENTS.DESELECT));
  }

  /**
   * Clear out text input
   */
  clearInput() {
    this.input.value = '';
  }
}
